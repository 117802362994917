import DatePickerInput from "../../controls/DatePickerInput";
import InputTextOnly from "../../controls/InputTextOnly";

export default function OrderDetails(props) {
  const { orderDetails, errors, handleInputChange, onCustomerRelatedChange } =
    props;

  return (
    <div className="mt-6 px-1 sm:px-6 space-y-4">
      <div className="text-center">
        <h3>Summary</h3>
      </div>
      <div className="border border-gray-400 p-1">
        <h3>Shipping address</h3>
        <div className="mt-3 grid grid-cols-1 gap-4 max-w-4xl">
          <div>
            <InputTextOnly
              label="Billing Address Line 1"
              name="line1"
              value={orderDetails.line1}
              labelOn={true}
              disabled={true}
            />
          </div>
          <div>
            <InputTextOnly
              label="Billing Address Line 2"
              name="line2"
              value={orderDetails.line2}
              labelOn={true}
              disabled={true}
            />
          </div>
        </div>
        <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
          <div>
            <InputTextOnly
              label="City"
              name="city"
              value={orderDetails.city}
              labelOn={true}
              disabled={true}
            />
          </div>
          <div>
            <InputTextOnly
              label="Province"
              name="province"
              value={orderDetails.province}
              labelOn={true}
              disabled={true}
            />
          </div>
          <div>
            <InputTextOnly
              label="State"
              name="state"
              value={orderDetails.state}
              labelOn={true}
              disabled={true}
            />
          </div>
          <div>
            <InputTextOnly
              label="Country"
              name="country_code"
              value={orderDetails.country_code}
              labelOn={true}
              disabled={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
