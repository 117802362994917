import { useEffect, useRef, useState } from "react";
import { ManageOrdersViewModel } from "../../viewmodels/orders/ManageOrdersViewModel";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { formatDateWithTime, withRouter } from "../../shared/utility";

import EmptyModal from "../../components/modals/EmptyModal";
import SlideOver from "../../components/slideOver/SlideOver";
import SimpleNotification from "../../components/SimpleNotification";
import { Link } from "react-router-dom";

import { Paginator } from "primereact/paginator";
import Pills, { PillType } from "../../components/controls/Pills";
import FilterDropdown from "../../components/filterDropdown/FilterDropdown";
import OrdersTable from "./OrdersTable";
import OrdersCardsForMobile from "./OrdersCardsForMobile";

function ManageOrders(props) {
  const { logout } = props;
  const {
    filterComponents,
    getCustomersForDropdown,
    getOrders,
    loading,
    orders,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    askDeleteOrder,
    filters,
    resetFilters,
    setShowFilters,
    showFilters,
    openImportOrder,
  } = ManageOrdersViewModel(logout);

  var currentPage = 1;
  useEffect(() => {
    getCustomersForDropdown();
    getOrders(null, currentPage, filters);
  }, []);

  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(20);

  const searchRef = useRef();

  const handleSearch = () => {
    getOrders(searchRef.current.value, currentPage, filters);
  };

  const handlePagination = (event) => {
    currentPage = event.page + 1;
    setFirst(event.first);
    setRows(event.rows);
    getOrders(searchRef.current.value, currentPage, filters);
  };

  return (
    <div>
      <SimpleNotification
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="mt-2">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center px-4 py-5 sm:px-4 card-custom">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Orders
              </h1>
              <div className="mt-5 flex-auto md:flex rounded-md">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <input
                    type="search"
                    name="searchProduct"
                    id="searchProduct"
                    className="block w-full rounded-lg rounded-l-md pl-2 py-2 md:py-0 sm:text-sm border border-gray-300 rounded-tr-none rounded-br-none"
                    placeholder="Search by name"
                    ref={searchRef}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? handleSearch() : null
                    }
                  />
                  <button
                    type="button"
                    className="-ml-px relative inline-flex items-center space-x-2 px-4 py-1 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                    onClick={() => handleSearch()}
                  >
                    <MagnifyingGlassIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </button>
                </div>

                <FilterDropdown
                  actionCall={() =>
                    getOrders(searchRef.current.value, currentPage, filters)
                  }
                  filterComponents={filterComponents}
                  resetFilters={() => {
                    resetFilters();
                  }}
                  showFilters={showFilters}
                  setShowFilters={setShowFilters}
                />

                <button
                  onClick={() => (window.location = "/create-order")}
                  className="hidden md:block ml-5 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Create Order
                </button>
                <button
                  className="hidden md:block ml-5 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  onClick={openImportOrder}
                >
                  Import
                </button>
              </div>
            </div>
          </div>
          <div className="mt-2 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {loading ? (
                  <div>
                    <div className="border h-10 shadow rounded-md p-4 w-full mx-auto">
                      <div className="animate-pulse flex space-x-4">
                        Loading
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <OrdersTable
                      askDeleteOrder={askDeleteOrder}
                      orders={orders}
                    />
                    <OrdersCardsForMobile
                      askDeleteOrder={askDeleteOrder}
                      orders={orders}
                    />
                  </div>
                )}
              </div>
              <div className="w-full">
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={orders.total}
                  onPageChange={handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        customClass="max-w-3xl"
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageOrders)
);
