import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { classNames, convertToDefEventPara } from "../../shared/utility";
import { CalendarIcon } from "@heroicons/react/24/outline";

export default function DatePickerInput(props) {
  const {
    classes,
    label,
    name,
    onChange,
    value,
    labelOn,
    error,
    explanation,
    labelDivClass,
    labelClass,
    dateFormat,
    parentClass,
    ...other
  } = props;

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        {value}
      </button>
    )
  );

  return (
    <div className={parentClass}>
      {labelOn ? (
        <div className="mb-1">
          <label
            htmlFor={name}
            className={classNames(
              labelClass ? labelClass : "text-sm",
              "block font-medium text-gray-900"
            )}
          >
            {label}
          </label>
        </div>
      ) : null}
      <div>
        <DatePicker
          wrapperClassName="w-full"
          selected={value ? new Date(value) : null}
          onChange={(e) => onChange(convertToDefEventPara(name, e))}
          dateFormat={dateFormat ? dateFormat : "LLL dd, YYY h:mm aa"}
          className={
            classes
              ? classes
              : "border border-gray-300 rounded-md mt-1 block w-full shadow-sm py-2 px-3 focus:outline-none sm:text-sm sm:max-w-xs md:max-w-full"
          }
          {...other}
          // icon={<CalendarIcon className="h-5 w-5" />}
          // showIcon={true}
          monthsShown={2}
          customInput={<ExampleCustomInput className="example-custom-input" />}
        />
        {error ? <p className="font-medium text-red-500">{error}</p> : null}
      </div>
    </div>
  );
}
