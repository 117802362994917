import { Link } from "react-router-dom";
import Pills, { PillType } from "../../components/controls/Pills";
import { convertStatus, formatDateWithTime } from "../../shared/utility";

export default function OrdersTable(props) {
  const { askDeleteOrder, orders } = props;

  return (
    <div className="card-custom hidden lg:block">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-white">
          <tr>
            <th
              scope="col"
              className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              PO #
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
            >
              Customer
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
            >
              Date
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
            >
              Total
            </th>
            <th
              scope="col"
              className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
            >
              Status
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {orders.data.length > 0 ? (
            orders.data.map((order) => (
              <tr key={order.id}>
                <td className="whitespace-nowrap py-1.5 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  {order.po_number}
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-700">
                  {order.company_name}
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-700">
                  {formatDateWithTime(order.created_at)}
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-700">
                  ${order.total_dollar_amount}
                </td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-700">
                  <Pills content={convertStatus(order.status)} />
                </td>
                <td className="relative whitespace-nowrap py-1.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <Link
                    className="rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    to={`/orders/${order.id}`}
                  >
                    Edit
                  </Link>
                  <button
                    onClick={() => {
                      askDeleteOrder(order);
                    }}
                    className="ml-2 rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center"
                colSpan={4}
              >
                No Orders
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
