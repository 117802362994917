import React from "react";
import { classNames } from "../../shared/utility";
import { InputText } from "primereact/inputtext";

export default function InputTextOnly(props) {
  const {
    parentClass,
    className,
    label,
    name,
    onChange,
    type,
    value,
    labelOn,
    error,
    suffix,
    explanation,
    ref,
    labelClass,
    ...other
  } = props;

  return (
    <div className={parentClass ? parentClass : null}>
      {labelOn ? (
        <label
          htmlFor={name}
          className={classNames(
            labelClass ? labelClass : "text-sm",
            "block font-medium text-gray-900"
          )}
        >
          {label}
        </label>
      ) : null}
      <div>
        <InputText
          name={name}
          className={className}
          value={value}
          onChange={onChange}
          ref={ref}
          suffix={suffix}
          {...other}
        />

        {error ? <p className="font-medium text-red-500">{error}</p> : null}
      </div>
    </div>
  );
}
