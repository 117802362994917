import { useEffect } from "react";
import { OrderDetailsViewModel } from "../../viewmodels/orders/OrderDetailsViewModel";

import { useParams } from "react-router-dom";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import EmptyModal from "../../components/modals/EmptyModal";
import SlideOver from "../../components/slideOver/SlideOver";
import SimpleNotification from "../../components/SimpleNotification";
import OrderDetailsForm from "../../components/forms/orders/OrderDetailsForm";
import MobileOrderDetailsForm from "../../components/forms/orders/MobileOrderDetailsForm";

function OrderDetails(props) {
  const { logout } = props;

  const { orderId } = useParams();

  const {
    customers,
    getCustomersForDropdown,
    getMyProducts,
    loading,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    showErrorModal,
    updateOrderApiCall,
    order,
    getOrderDetails,
    onUpdateOrderItem,
    deleteOrderItemApiCall,
    addProductToOrderApiCall,
    employeeOptions,
    getUsersForDropdown,
    printOrder,
    createEstimate,
    askDeleteOrder,
    downloadEstimate,
  } = OrderDetailsViewModel(orderId, logout);

  const apiFunctionCalls = async function () {
    getOrderDetails();
    getCustomersForDropdown();
    getUsersForDropdown();
  };

  useEffect(() => {
    apiFunctionCalls();
  }, []);

  return (
    <div>
      <SimpleNotification
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="mt-2 px-4 sm:px-8">
        <div className="sm:items-center">
          {order ? (
            <div>
              <div className="hidden md:block">
                <OrderDetailsForm
                  customers={customers}
                  getMyProducts={getMyProducts}
                  isEdit={true}
                  loading={loading}
                  order={order}
                  setEmptyModelOpen={setEmptyModelOpen}
                  showErrorModal={showErrorModal}
                  updateOrderApiCall={updateOrderApiCall}
                  onUpdateOrderItem={onUpdateOrderItem}
                  deleteOrderItemApiCall={deleteOrderItemApiCall}
                  addProductToOrderApiCall={addProductToOrderApiCall}
                  employeeOptions={employeeOptions}
                  printOrder={printOrder}
                  createEstimate={createEstimate}
                  downloadEstimate={downloadEstimate}
                />
              </div>
              <div className="md:hidden">
                <MobileOrderDetailsForm
                  getMyProducts={getMyProducts}
                  isEdit={true}
                  order={order}
                  setEmptyModelOpen={setEmptyModelOpen}
                  showErrorModal={showErrorModal}
                  updateOrderApiCall={updateOrderApiCall}
                  deleteOrderItemApiCall={deleteOrderItemApiCall}
                  addProductToOrderApiCall={addProductToOrderApiCall}
                  askDeleteOrder={askDeleteOrder}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        customClass="max-w-3xl"
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderDetails)
);
