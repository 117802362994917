import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { convertToCurrencyFormat } from "../../../shared/utility";

export default function OrderProductsTable(props) {
  const { loading, values, onItemEditComplete, askDeleteOrderItem } = props;

  const productDetailsBody = (rowData) => {
    return (
      <p>
        <span className="text-gray-900 font-semibold">
          {rowData.product_name}
        </span>
        <br />
        <span className="text-gray-700">PLU:{rowData.plu}</span>
      </p>
    );
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
        className="w-24"
      />
    );
  };

  const onRowEditComplete = (e) => {
    let { newData, index } = e;

    var sub_dollar_amount =
      (newData.price - newData.discount) * newData.quantity;
    var total_dollar_amount = sub_dollar_amount;
    if (newData.taxable === 1) {
      total_dollar_amount = parseFloat(sub_dollar_amount) * 1.13;
    }

    newData["sub_dollar_amount"] = sub_dollar_amount;
    newData["total_dollar_amount"] = total_dollar_amount;

    onItemEditComplete(newData, index);
  };

  const orderQuantityBody = (rowData) => {
    return <p>{rowData.quantity}</p>;
  };

  const shippedQuantityBody = (rowData) => {
    return <p>{rowData.shipped_amount}</p>;
  };

  const unitCostBody = (rowData) => {
    return <p>{convertToCurrencyFormat(rowData.price)}</p>;
  };

  const itemDiscountBody = (rowData) => {
    return <p>{convertToCurrencyFormat(rowData.discount)}</p>;
  };

  const totalCostBody = (rowData) => {
    return <p>{convertToCurrencyFormat(rowData.total_dollar_amount)}</p>;
  };

  const itemTaxableBody = (rowData) => {
    return <p>{rowData.taxable === 1 ? "Yes" : "No"}</p>;
  };

  return (
    <div className="mt-5">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {loading ? (
            <div>
              <div className="border h-10 shadow rounded-md p-4 w-full mx-auto">
                <div className="animate-pulse flex space-x-4">Loading</div>
              </div>
            </div>
          ) : (
            <div className="overflow-hidden">
              <DataTable
                value={values.order_items}
                dataKey="id"
                tableClassName="min-w-full bg-white divide-y divide-gray-300 order-items-table-parent"
                cellClassName="p-2 bg-white border-1-bottom-gray text-sm"
                editMode="row"
                onRowEditComplete={onRowEditComplete}
              >
                <Column header="Product" body={productDetailsBody} />

                <Column
                  header="Order quantity"
                  field="quantity"
                  style={{ textAlign: "right" }}
                  body={orderQuantityBody}
                  editor={(options) => textEditor(options)}
                />

                <Column
                  header="Shipped"
                  field="shipped_amount"
                  style={{ textAlign: "right" }}
                  body={shippedQuantityBody}
                  editor={(options) => textEditor(options)}
                />

                <Column header="Taxable" body={itemTaxableBody} />

                <Column
                  header="Unit Cost"
                  field="price"
                  style={{ textAlign: "right" }}
                  body={unitCostBody}
                  editor={(options) => textEditor(options)}
                />

                <Column
                  header="Discount"
                  field="discount"
                  style={{ textAlign: "right" }}
                  body={itemDiscountBody}
                  editor={(options) => textEditor(options)}
                />

                <Column
                  header="Total Cost"
                  field="total_cost"
                  style={{ textAlign: "right" }}
                  body={totalCostBody}
                />

                {/* <Column
                  header="Unit Type"
                  field="unit_type"
                  headerStyle={{ minWidth: "8rem" }}
                  body={unitTypeBody}
                  editor={(options) => itemTypeDropdown(options)}
                /> */}

                <Column
                  rowEditor={true}
                  headerStyle={{ minWidth: "6rem" }}
                  bodyStyle={{ textAlign: "center" }}
                />
              </DataTable>
            </div>
          )}
          <div className="w-full text-right mt-4">
            <p className="font-bold">
              Subtotal: {convertToCurrencyFormat(values.subtotal_dollar_amount)}
            </p>
            <p className="font-bold">
              Taxes: {convertToCurrencyFormat(values.dollar_tax_amount)}
            </p>
            <p className="font-bold">
              Total: {convertToCurrencyFormat(values.total_dollar_amount)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
