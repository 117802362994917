import React from "react";

import InputTextOnly from "../../controls/InputTextOnly";
import { classNames, convertToCurrencyFormat } from "../../../shared/utility";

export default function CreateNewOrderForm(props) {
  const {
    primaryCategories,
    subCategories,
    products,
    primaryCategorySelected,
    subCategorySelected,
    onProductQuantityChanged,
  } = props;

  return (
    <div className="h-full">
      <div>
        <div className="space-y-6">
          <div>
            {primaryCategories && !subCategories && !products ? (
              primaryCategories.length > 0 ? (
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
                  <div className="col-span-2 sm:col-span-4">
                    <p className="text-xl font-bold">
                      Please select a category
                    </p>
                  </div>
                  {primaryCategories.map((cat, index) => (
                    <div
                      className="items-card-custom"
                      onClick={() => primaryCategorySelected(cat)}
                    >
                      <div className="min-h-32 sm:min-h-40">
                        <img
                          src={cat.image1}
                          alt={cat.image1}
                          title={cat.image1}
                          className="media-image rounded-md my-auto mx-auto"
                          height="140"
                          width="140"
                        />
                      </div>
                      <p className="">{cat.name}</p>
                    </div>
                  ))}
                </div>
              ) : (
                "It seems no categories or products are assigned"
              )
            ) : null}
            {subCategories && !products ? (
              subCategories.length > 0 ? (
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
                  <div className="col-span-2 sm:col-span-4">
                    <p className="text-xl font-bold">
                      Please select a sub category
                    </p>
                  </div>
                  {subCategories.map((cat, index) => (
                    <div
                      className="items-card-custom"
                      onClick={() => subCategorySelected(cat)}
                    >
                      <div className="min-h-32 sm:min-h-40">
                        <img
                          src={cat.image1}
                          alt={cat.image1}
                          title={cat.image1}
                          className="media-image rounded-md my-auto mx-auto"
                          height="140"
                          width="140"
                        />
                      </div>
                      <p className="">{cat.name}</p>
                    </div>
                  ))}
                </div>
              ) : (
                "It seems no sub category or products are assigned"
              )
            ) : null}
            {products ? (
              products.length > 0 ? (
                <div className="space-y-4">
                  <p className="text-xl font-bold">
                    {products.length} products
                  </p>
                  <div className="grid grid-cols-2 sm:grid-cols-2 xl:grid-cols-3 gap-3">
                    {products.map((product, index) => (
                      <div className="flex flex-col justify-between items-card-custom">
                        <div className="flex">
                          <div className="min-h-32 sm:min-h-32 p-1">
                            <img
                              src={product.image1}
                              alt={product.image1}
                              title={product.image1}
                              className="media-image rounded-md my-auto mx-auto"
                              height="250"
                              width="250"
                            />
                          </div>
                          <div className="text-center my-auto">
                            <p className="text-sm font-bold">{product.name}</p>
                            <p className="text-sm font-bold text-gray-600">
                              {product.plu}
                            </p>
                            <div className="flex gap-2">
                              <p className="text-sm">
                                {convertToCurrencyFormat(product.price)}
                              </p>
                              <p className="text-sm">
                                Discount:{" "}
                                {convertToCurrencyFormat(
                                  product.discount ? product.discount : 0
                                )}
                              </p>
                            </div>
                            <p
                              className={classNames(
                                product.stock < product.low_stock_threshold
                                  ? "text-red-600"
                                  : "text-black",
                                "text-sm font-bold"
                              )}
                            >
                              In stock: {product.stock}
                            </p>
                            <div className="flex py-2 gap-1 w-48 mx-auto">
                              <button
                                className="bg-indigo-500 text-white py-1.5 px-3 rounded-md"
                                type="button"
                                onClick={() =>
                                  onProductQuantityChanged(
                                    null,
                                    product,
                                    index,
                                    false,
                                    true
                                  )
                                }
                                disabled={product.quantity === 0}
                              >
                                -
                              </button>
                              <InputTextOnly
                                onChange={(e) =>
                                  onProductQuantityChanged(
                                    e.target.value,
                                    product,
                                    index,
                                    false,
                                    false
                                  )
                                }
                                value={product.quantity}
                                labelOn={false}
                                className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm sm:max-w-xs md:max-w-full text-center"
                                parentClass="flex-grow"
                                min="0"
                                type="number"
                              />
                              <button
                                className="bg-indigo-500 text-white py-1.5 px-3 rounded-md"
                                type="button"
                                onClick={() =>
                                  onProductQuantityChanged(
                                    null,
                                    product,
                                    index,
                                    true,
                                    false
                                  )
                                }
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p className="text-xl font-bold">
                  No products are assigned to this category
                </p>
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
