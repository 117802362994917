import React, { useEffect, useState } from "react";

import { useForm } from "../useForm";

import AsyncSelect from "react-select/async";

import InputTextOnly from "../../controls/InputTextOnly";
import SelectMenu from "../../controls/SelectMenu";
import OrderProductsTable from "./OrderProductsTable";
import DeleteConfirmationForm from "../DeleteConfirmationForm";
import DatePickerInput from "../../controls/DatePickerInput";
import AddProductToOrderForm from "./modals/AddProductToOrderForm";
import OrderNotes from "./modals/OrderNotes";
import {
  convertToDefEventPara,
  orderStatusOptions,
} from "../../../shared/utility";
import { classNames } from "primereact/utils";

const initialFValues = {
  id: 0,
  customer: "",
  customer_id: 0,
  subtotal_dollar_amount: 0,
  total_dollar_amount: 0,
  order_items: [],
};

export default function OrderDetailsForm(props) {
  const {
    customers,
    getMyProducts,
    employeeOptions,
    isEdit,
    loading,
    order,
    setEmptyModelOpen,
    showErrorModal,
    updateOrderApiCall,
    onUpdateOrderItem,
    deleteOrderItemApiCall,
    addProductToOrderApiCall,
    printOrder,
    createEstimate,
    downloadEstimate,
  } = props;

  const updateItemLocally = (newData, index) => {
    let currentItems = [...values.order_items];
    currentItems[index] = newData;
    setValues({ ...values, order_items: currentItems });
  };

  const onItemEditComplete = async function (newData, index) {
    if (isEdit) {
      const onUpdateCompleted = await onUpdateOrderItem(newData);

      if (onUpdateCompleted.success) {
        updateItemLocally(newData, index);
      }
    }
  };

  const [asyncSelectValue, setAsyncSelectValue] = useState({
    value: -1,
    label: "",
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    temp.customer = null;
    if (values.customer === "") {
      temp.customer = "Please fill out field.";
    }

    temp.expected_date = null;
    if (values.expected_date === "") {
      temp.expected_date = "Please fill out field.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    false,
    validate
  );

  useEffect(() => {
    if (order) {
      const orderCopy = {
        ...order,
        status: orderStatusOptions.filter(
          (option) => option.value === order.status
        )[0],
      };
      setValues(orderCopy);
    }
  }, [order]);

  const addProductToOrder = async function (addedProductValues) {
    setAsyncSelectValue({ value: -1, label: "" });

    const callResult = await addProductToOrderApiCall(
      values.id,
      addedProductValues
    );
    const newOrderProducts = [...values.order_items, callResult.data];

    setEmptyModelOpen({
      childComponent: null,
      open: false,
    });
  };

  const updateOrder = () => {
    if (validate()) {
      updateOrderApiCall(values);
    }
  };

  /* TODO */
  const makeDeleteOrderItemApiCall = async function (itemId) {
    var onItemDeleted = { success: false };
    if (isEdit) {
      onItemDeleted = await deleteOrderItemApiCall(itemId);
    }

    setEmptyModelOpen({
      childComponent: null,
      open: false,
    });
  };

  const askDeleteOrderItem = function (product) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => makeDeleteOrderItemApiCall(product.id)}
          message={`Are you sure you want to ${product.product_name}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  const loadOptions = async function (input, callback) {
    if (input.length > 2) {
      const result = await getMyProducts(input);
      callback(result);
    }
  };

  const handleSubmit = () => {
    updateOrder();
  };

  return (
    <div className="space-y-4">
      <div className="flex">
        <h1 className="text-xl font-semibold leading-6 text-gray-900">
          Order: {order ? order.po_number : null}
        </h1>

        <div className="flex gap-2 ml-auto">
          <button
            type="button"
            className={classNames(
              values.id > 0 ? "" : null,
              "inline-flex justify-center rounded-md bg-gray-500 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            )}
            onClick={() => printOrder()}
          >
            Print
          </button>
          <button
            type="submit"
            className="inline-flex justify-center rounded-md bg-main-purple px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSubmit}
          >
            {values.id > 0 ? "Save" : "Create"}
          </button>
          {order.quickbooks_estimate_id > 0 ? (
            <button
              type="submit"
              className="inline-flex justify-center rounded-md bg-green-600 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={downloadEstimate}
            >
              Download QB Estimate
            </button>
          ) : (
            <button
              type="submit"
              className="inline-flex justify-center rounded-md bg-green-600 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => createEstimate()}
            >
              Estimate to QB
            </button>
          )}
        </div>
      </div>
      <div className="card-custom p-3 grid grid-cols-5 gap-5">
        <SelectMenu
          parentClass="col-span-2"
          handleInputChange={handleInputChange}
          name="customer"
          options={customers}
          defaultValue={isEdit ? order.customer : null}
          title={"Customer"}
          error={errors.customer}
          showLabel={true}
        />

        <InputTextOnly
          label="PO #"
          name="po_number"
          onChange={handleInputChange}
          value={values.po_number}
          labelOn={true}
          type="text"
          error={errors.po_number}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
        />

        <SelectMenu
          handleInputChange={handleInputChange}
          name="created_by"
          options={employeeOptions}
          defaultValue={isEdit ? order.created_by : null}
          title={"Salesperson"}
          error={errors.created_by}
          showLabel={true}
        />

        <SelectMenu
          handleInputChange={handleInputChange}
          name="shipped_by"
          options={employeeOptions}
          defaultValue={isEdit ? order.shipped_by : null}
          title={"Shipped by"}
          error={errors.shipped_by}
          showLabel={true}
        />

        <DatePickerInput
          label="Order Date"
          name="created_at"
          onChange={handleInputChange}
          value={values.created_at}
          labelOn={true}
          minDate={new Date()}
          dateFormat="LLL dd, YYY"
          type="text"
          className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
        />

        <DatePickerInput
          label="Expected Date"
          name="expected_date"
          onChange={handleInputChange}
          value={values.expected_date ? values.expected_date : null}
          labelOn={true}
          minDate={new Date()}
          dateFormat="LLL dd, YYY"
          type="text"
          className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          error={errors.expected_date}
        />
        <SelectMenu
          handleInputChange={handleInputChange}
          name="status"
          options={orderStatusOptions.filter((option) => option.value !== -1)}
          value={isEdit ? values.status : null}
          title={"Status"}
          error={errors.status}
          showLabel={true}
        />
      </div>

      <div className="card-custom p-3">
        <div className="flex gap-2">
          <p className="text-md text-gray-700 font-semibold">Notes</p>
          <button
            onClick={() =>
              setEmptyModelOpen({
                childComponent: (
                  <OrderNotes
                    orderNote={values.notes}
                    confirmAction={(newNote) =>
                      handleInputChange(convertToDefEventPara("notes", newNote))
                    }
                    setOpen={setEmptyModelOpen}
                  />
                ),
                open: true,
              })
            }
            className="text-sm font-semibold main-purple"
          >
            Edit
          </button>
        </div>
        <p className="text-sm text-gray-700 font-normal">
          {values.notes ? values.notes : <br />}
        </p>
      </div>

      <div className="card-custom p-3 py-5">
        <AsyncSelect
          cacheOptions
          loadOptions={loadOptions}
          options={[]}
          className="w-full"
          onChange={(e) => {
            setEmptyModelOpen({
              childComponent: (
                <AddProductToOrderForm
                  product={e}
                  confirmAction={addProductToOrder}
                  isEdit={false}
                  setOpen={() => {
                    setEmptyModelOpen({
                      open: false,
                    });
                    setAsyncSelectValue({ value: -1, label: "" });
                  }}
                />
              ),
              open: true,
            });
          }}
          value={asyncSelectValue}
        />

        <OrderProductsTable
          addProductToOrder={addProductToOrder}
          loading={false}
          values={values}
          setEmptyModelOpen={setEmptyModelOpen}
          askDeleteOrderItem={askDeleteOrderItem}
          onItemEditComplete={onItemEditComplete}
        />
      </div>
    </div>
  );
}
