import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../shared/makeApiCall";
import { SimpleNotificationType } from "../../components/SimpleNotification";
import DeleteConfirmationForm from "../../components/forms/DeleteConfirmationForm";
import ModelErrorComponent from "../../components/modals/ModelErrorComponent";

export function OrderDetailsViewModel(orderId, logout) {
  const [customers, setCustomers] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getOrderDetails = async function () {
    let callResult = await makePostApiCall(`fetchOrder/${orderId}`);
    if (callResult.success) {
      setOrder(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
  };

  const getCustomersForDropdown = async function () {
    let callResult = await makePostApiCall(`getCustomersForDropdown`);
    if (callResult.success) {
      setCustomers(callResult.data);
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
  };

  const getMyProducts = async function (search) {
    const payload = {
      customer_group_id: order.customer_group_id,
      search: search,
    };
    let callResult = await makePostApiCall(`fetchProductsForDropdown`, payload);
    if (callResult.success) {
      return callResult.data;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
  };

  const showErrorModal = (title, subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title={title}
        />
      ),
      open: true,
    });
  };

  const updateOrderApiCall = async function (payload) {
    let callResult = await makePostApiCall(`updateOrder/${orderId}`, payload);
    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
  };

  const onUpdateOrderItem = async function (payload) {
    let callResult = await makePostApiCall(`updateOrderItem/${payload.id}`, {
      ...payload,
    });
    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
    return callResult;
  };

  const deleteOrderItemApiCall = async function (id) {
    let callResult = await makePostApiCall(`deleteOrderItem/${id}`);

    if (callResult.success) {
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
    return callResult;
  };

  const addProductToOrderApiCall = async function (orderId, payload) {
    let callResult = await makePostApiCall(
      `addProductToOrder/${orderId}`,
      payload
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Product added!",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
    return callResult;
  };

  const getUsersForDropdown = async function (type, department) {
    const payload = { department: department, type: type };
    let callResult = await makePostApiCall(`usersForDropdown`, payload);

    if (callResult.success) {
      setEmployeeOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const printOrder = async function () {
    let callResult = await makePostApiCall(`printOrder/${orderId}`);

    if (callResult.success) {
      window.open(callResult.data.location, "_blank");
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createEstimate = async function () {
    let callResult = await makePostApiCall(`createEstimate/${orderId}`);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const deleteOrder = async function (id) {
    let callResult = await makePostApiCall(`/deleteOrder/${id}`);

    if (callResult.success) {
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      window.location = "/orders";
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const askDeleteOrder = function (order) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => deleteOrder(order.id)}
          message={`Are you sure you want to delete ${order.po_number}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: "application/pdf" });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  }

  const downloadEstimate = async function (orderId) {
    let callResult = await makePostApiCall(`readEstimateFromQb/${orderId}`);

    if (callResult.success) {
      var sampleArr = base64ToArrayBuffer(callResult.data);
      saveByteArray("Sample Report", sampleArr);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
  };

  return {
    customers,
    getCustomersForDropdown,
    getMyProducts,
    loading,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    showErrorModal,
    updateOrderApiCall,
    order,
    getOrderDetails,
    onUpdateOrderItem,
    deleteOrderItemApiCall,
    addProductToOrderApiCall,
    employeeOptions,
    getUsersForDropdown,
    printOrder,
    createEstimate,
    askDeleteOrder,
    downloadEstimate,
  };
}
