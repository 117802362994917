import { useEffect, useState } from "react";
import InputTextOnly from "../../controls/InputTextOnly";
import DatePickerInput from "../../controls/DatePickerInput";
import { classNames, convertToCurrencyFormat } from "../../../shared/utility";

export default function OrderSummary(props) {
  const { createOrderFromCart, orderDetails, cart, setOrderDetails, errors } =
    props;

  const [orderTotals, setOrderTotals] = useState({
    count: 0,
    subTotal: 0,
    taxes: 0,
    totalPrice: 0,
  });

  useEffect(() => {
    if (orderDetails) {
      var currCount = 0;
      var subTotal = 0;
      var totalPrice = 0;
      var taxes = 0;
      if (cart) {
        cart.forEach((element) => {
          currCount += parseFloat(element.quantity);
          subTotal += parseFloat(element.sub_dollar_amount);

          if (element.taxable === 1) {
            taxes +=
              parseFloat(element.total_dollar_amount) -
              parseFloat(element.sub_dollar_amount);
          }

          totalPrice += parseFloat(element.total_dollar_amount);
        });
      }

      setOrderTotals({
        count: currCount,
        subTotal: convertToCurrencyFormat(subTotal),
        taxes: convertToCurrencyFormat(taxes),
        totalPrice: convertToCurrencyFormat(totalPrice),
      });
    }
  }, [orderDetails, cart]);

  return (
    <div className="p-4  bg-gray-200 col-span-2">
      <div className="space-y-4 w-96">
        <InputTextOnly
          label="PO #"
          name="po_number"
          onChange={(e) => {
            const po_number = e.target.value;
            setOrderDetails({ ...orderDetails, po_number: po_number });
          }}
          value={orderDetails.po_number}
          labelOn={true}
          type="text"
          error={errors.po_number}
          labelClass="text-lg"
          parentClass="max-w-60"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-40-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-lg"
        />

        <DatePickerInput
          label="Expected Date"
          name="expected_date"
          onChange={(e) => {
            const expected_date = e.target.value;
            setOrderDetails({ ...orderDetails, expected_date: expected_date });
          }}
          value={orderDetails.expected_date}
          labelOn={true}
          minDate={new Date()}
          dateFormat="LLL dd, YYY"
          type="text"
          labelClass="text-lg"
          parentClass="max-w-60"
          className="block w-full bg-white border border-gray-300 rounded-md shadow-sm py-40-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-lg"
          error={errors.expected_date}
        />

        <div className="space-y-1 text-lg">
          <div className="flex justify-between">
            <p className="font-semibold">Units</p> <p>{orderTotals.count}</p>
          </div>
          <div className="flex justify-between">
            <p className="font-semibold">Subtotal</p>{" "}
            <p>{orderTotals.subTotal}</p>
          </div>
          <div className="flex justify-between">
            <p className="font-semibold">Taxes</p> <p>{orderTotals.taxes}</p>
          </div>
          <div className="flex justify-between text-xl pt-2">
            <p className="font-bold">Total</p> <p>{orderTotals.totalPrice}</p>
          </div>
        </div>

        <div className="space-y-2">
          <h3 className="font-bold text-2xl">Shipping address</h3>
          {orderDetails ? (
            <div className="grid grid-cols-2 gap-2">
              <div className="col-span-2">
                {AddressArea("Shipping Address 1", orderDetails.line1)}
              </div>
              <div className="col-span-2">
                {AddressArea("Shipping Address 2", orderDetails.line2)}
              </div>
              {AddressArea("City", orderDetails.city)}
              {AddressArea("Province", orderDetails.province)}
              {AddressArea("Postal Code", orderDetails.postal_code)}
              {AddressArea("Country", orderDetails.country_code)}
            </div>
          ) : null}
        </div>

        <div className={classNames(cart.length > 0 ? "py-4" : "hidden")}>
          <button
            type="submit"
            className="justify-center rounded-md bg-main-purple px-3 py-3 text-lg font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full"
            onClick={() =>
              createOrderFromCart({
                cart: cart,
                orderDetails: orderDetails,
              })
            }
          >
            Create order
          </button>
        </div>
      </div>
    </div>
  );
}

function AddressArea(title, text) {
  return (
    <div>
      <p className="block font-bold text-gray-900 text-md">{title}</p>
      <p className="text-md">{text ? text : "N/A"}</p>
    </div>
  );
}
