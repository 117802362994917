import { useEffect } from "react";
import { useForm } from "../useForm";

import InputTextOnly from "../../controls/InputTextOnly";
import { classNames, convertToDefEventPara } from "../../../shared/utility";
import SelectMenu from "../../controls/SelectMenu";

const userTypeOptions = [
  { value: 0, label: "Sales Rep" },
  { value: 1, label: "Administrator" },
  { value: 2, label: "Customer" },
];

const statusOptions = [
  { value: 0, label: "Active" },
  { value: 1, label: "Deleted" },
];

const initialFValues = {
  first_name: "",
  last_name: "",
  email: "",
  type: 0,
  phone_number: "",
  status: 0,
};

export default function UserDetailsForm(props) {
  const { user, actionCall, isEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    temp.first_name = null;
    if (values.first_name === "") {
      temp.first_name = "Please fill out field.";
    }

    temp.last_name = null;
    if (values.last_name === "") {
      temp.last_name = "Please fill out field.";
    }

    temp.email = null;
    if (values.email === "") {
      temp.email = "Please fill out field.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      actionCall(values);
    }
  };

  useEffect(() => {
    if (user) {
      setValues({ ...values, ...user });
    }
  }, [user, setValues]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="p-4">
        <div className="space-y-3">
          <div>
            <div className="grid grid-cols-2 gap-4 max-w-4xl">
              <div>
                <InputTextOnly
                  label="First name"
                  name="first_name"
                  onChange={handleInputChange}
                  value={values.first_name}
                  labelOn={true}
                  error={errors.first_name}
                />
              </div>
              <div>
                <InputTextOnly
                  label="Last name"
                  name="last_name"
                  onChange={handleInputChange}
                  value={values.last_name}
                  labelOn={true}
                  error={errors.last_name}
                />
              </div>
              <div>
                <InputTextOnly
                  label="Email"
                  name="email"
                  onChange={handleInputChange}
                  value={values.email}
                  labelOn={true}
                  error={errors.email}
                />
              </div>
              <div>
                <InputTextOnly
                  label="Phone number"
                  name="phone_number"
                  onChange={handleInputChange}
                  value={values.phone_number}
                  labelOn={true}
                  error={errors.phone_number}
                />
              </div>
              <div>
                <SelectMenu
                  handleInputChange={handleInputChange}
                  name="type"
                  defaultValue={user ? user.type : []}
                  options={userTypeOptions}
                  title={"Access type"}
                />
              </div>
              <div>
                <SelectMenu
                  handleInputChange={handleInputChange}
                  name="status"
                  defaultValue={user ? user.status : {}}
                  options={statusOptions}
                  title={"Status"}
                />
              </div>
              <div
                className={classNames(
                  isEdit
                    ? "hidden"
                    : "col-span-2 grid grid-cols-1 sm:grid-cols-2 gap-4"
                )}
              >
                <div>
                  <InputTextOnly
                    label="Password"
                    name="password"
                    onChange={handleInputChange}
                    value={values.password}
                    labelOn={true}
                    error={errors.password}
                    type="password"
                  />
                </div>
                <div>
                  <InputTextOnly
                    label="Confirm password"
                    name="password_confirmation"
                    onChange={handleInputChange}
                    value={values.password_confirmation}
                    labelOn={true}
                    error={errors.password_confirmation}
                    type="password"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sm:flex-no-wrap">
            <div className="my-auto flex items-center">
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center mt-3"
              >
                {isEdit ? "Save" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
