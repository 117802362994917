import AsyncSelect from "react-select/async";

import InputTextOnly from "../../controls/InputTextOnly";
import AddProductToOrderForm from "../orders/modals/AddProductToOrderForm";
import DollarInputText from "../../controls/DollarInputText";
import InputNumberOnly from "../../controls/InputNumberOnly";

export default function ItemsScreen(props) {
  const {
    cart,
    onRemoveProductFromCart,
    onHandleInputChange,
    isEdit,
    setEmptyModelOpen,
    addProductToOrder,
    setAsyncSelectValue,
    asyncSelectValue,
    loadOptions,
  } = props;

  var cartLength = 0;
  if (cart) {
    cartLength = cart.length;
  }
  return (
    <div className="space-y-4 overflow-y-auto">
      {isEdit ? (
        <div className="content-end">
          <AsyncSelect
            cacheOptions
            loadOptions={loadOptions}
            options={[]}
            className="w-full"
            onChange={(e) => {
              setEmptyModelOpen({
                childComponent: (
                  <AddProductToOrderForm
                    product={e}
                    confirmAction={addProductToOrder}
                    isEdit={false}
                    setOpen={() => {
                      setEmptyModelOpen({
                        open: false,
                      });
                      setAsyncSelectValue({ value: -1, label: "" });
                    }}
                  />
                ),
                open: true,
              });
            }}
            value={asyncSelectValue}
          />
        </div>
      ) : null}
      <div className="overflow-y-auto space-y-2">
        {cartLength > 0 ? (
          cart.map((product, index) => (
            <div className="card-custom p-3">
              <div className="flex justify-between">
                <div>
                  <p className="font-bold text-sm sm:text-lg">{product.name}</p>
                  <p className="text-gray-700 font-bold text-sm">
                    {product.plu}
                  </p>
                </div>
                <div>
                  <button
                    className="bg-gray-500 text-white py-1.5 px-3 rounded-md my-auto"
                    type="button"
                    onClick={() => onRemoveProductFromCart(product, index)}
                  >
                    X
                  </button>
                </div>
              </div>
              <div className="flex gap-2">
                <div>
                  <img
                    src={product.image1}
                    alt={product.image1}
                    title={product.image1}
                    className="media-image rounded-md my-auto mx-auto w-24 h-24"
                  />

                  <div className="flex p-2 gap-1">
                    <button
                      className="bg-indigo-500 text-white py-1.5 px-3 rounded-md my-auto"
                      type="button"
                      onClick={() =>
                        onHandleInputChange(
                          "quantity",
                          parseFloat(product.quantity) - 1,
                          index
                        )
                      }
                      disabled={product.quantity === 0}
                    >
                      -
                    </button>
                    <InputTextOnly
                      onChange={(e) =>
                        onHandleInputChange("quantity", e.target.value, index)
                      }
                      value={product.quantity}
                      labelOn={false}
                      parentClass="my-auto"
                      className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none w-14 sm:text-sm sm:max-w-xs md:max-w-full sm:w-24 text-center"
                      min="0"
                      type="number"
                    />
                    <button
                      className="bg-indigo-500 text-white py-1.5 px-3 rounded-md my-auto"
                      type="button"
                      onClick={() =>
                        onHandleInputChange(
                          "quantity",
                          parseFloat(product.quantity) + 1,
                          index
                        )
                      }
                    >
                      +
                    </button>
                  </div>
                </div>

                <div className="space-y-4 my-auto">
                  <div className="flex gap-2">
                    <DollarInputText
                      label="Discount"
                      name="discount"
                      parentClass="my-auto text-center"
                      className="block w-full rounded-md focus:outline-none w-14 sm:text-sm sm:w-24 text-center"
                      onChange={(e) => {
                        onHandleInputChange(
                          e.target.name,
                          e.target.value,
                          index
                        );
                      }}
                      value={product.discount}
                      labelOn={true}
                    />
                    <DollarInputText
                      label="Price"
                      name="price"
                      parentClass="my-auto text-center"
                      className="block w-full rounded-md focus:outline-none w-14 sm:text-sm sm:w-24 text-center"
                      onChange={(e) => {
                        onHandleInputChange(
                          e.target.name,
                          e.target.value,
                          index
                        );
                      }}
                      value={product.price}
                      labelOn={true}
                    />

                    <InputNumberOnly
                      label="Taxes"
                      name="taxes"
                      parentClass="my-auto text-center"
                      className="block w-full rounded-md focus:outline-none w-14 sm:text-sm sm:w-24 text-center"
                      onChange={(e) => {
                        onHandleInputChange(
                          e.target.name,
                          e.target.value,
                          index
                        );
                      }}
                      value={product.taxable === 1 ? "13" : "0"}
                      labelOn={true}
                      disabled={true}
                      suffix="%"
                    />
                    <DollarInputText
                      label="Total incl. tax"
                      name="total_dollar_amount"
                      parentClass="my-auto text-center"
                      className="block w-full rounded-md focus:outline-none w-14 sm:text-sm sm:w-24 text-center text-black"
                      onChange={(e) => {}}
                      value={product.total_dollar_amount}
                      labelOn={true}
                      disabled={true}
                    />
                  </div>
                  <InputTextOnly
                    name="notes"
                    onChange={(e) =>
                      onHandleInputChange(e.target.name, e.target.value, index)
                    }
                    label="Notes"
                    value={product.notes}
                    labelOn={true}
                    parentClass="my-auto"
                    className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none text-sm"
                    min="0"
                    type="text"
                  />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>
            <p className="font-bold text-lg">
              Your cart is empty, please add items to your cart
            </p>
            <button
              type="button"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center mt-3"
              onClick={() => (window.location = "/create-order")}
            >
              Start new order
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
