import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import ModelErrorComponent from "../../components/modals/ModelErrorComponent";
import { SimpleNotificationType } from "../../components/SimpleNotification";
import { useForm } from "../../components/forms/useForm";
import {
  convertToTwoDecimals,
  updateObjectInArray,
} from "../../shared/utility";

import ChangeCustomerForm from "../../components/forms/orders/modals/ChangeCustomerForm";

export function CreateOrderViewModel(
  cart,
  orderDetails,
  modifyCart,
  logout,
  setOrderDetails
) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [customers, setCustomers] = useState(null);
  const [primaryCategories, setPrimaryCategories] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const [products, setProducts] = useState(null);
  const [breadCrumbs, setBreadCrumbs] = useState([
    // {
    //   name: "Orders",
    //   href: "/orders",
    //   current: false,
    // },
    // { name: "Create New", href: "/create-order", current: true },
  ]);

  const getCustomersForDropdown = async function (searchVal) {
    setLoading(true);

    let callResult = await makePostApiCall(`getCustomersForDropdown`, {
      search: searchVal,
    });
    setLoading(false);
    if (callResult.success) {
      setCustomers(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    temp.customer = null;
    if (values.customer === "") {
      temp.customer = "Please fill out field.";
    }

    temp.expected_date = null;
    if (values.expected_date === "") {
      temp.expected_date = "Please fill out field.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    orderDetails,
    false,
    validate
  );

  const handleSubmit = () => {
    if (values.id > 0) {
      // updateOrder();
    } else {
      createOrderApiCall();
    }
  };

  const onCustomerSelected = (payload) => {
    const selectedCust = {
      ...values,
      company_name: payload.customer.label,
      customer_id: payload.customer.value,
      customer_group_id: payload.customer.group_id,
      city: payload.customer.city,
      line1: payload.customer.line1,
      line2: payload.customer.line2,
      postal_code: payload.customer.postal_code,
      province: payload.customer.province,
      country_code: payload.customer.country_code,
      po_number: payload.po_number,
      expected_date: payload.expected_date,
    };

    setValues(selectedCust);
    setOrderDetails(selectedCust);
    getPrimaryCategoriesForCustomerGroup(
      payload.customer.label,
      payload.customer.group_id
    );
  };

  const primaryCategorySelected = (cat) => {
    setValues({
      ...values,
      primary_category: cat.id,
    });
    getSubCategoriesOrProductsForPrimaryCategory(
      cat.name,
      cat.id,
      orderDetails.customer_group_id
    );
  };

  const subCategorySelected = (cat) => {
    setValues({
      ...values,
      sub_category: cat.id,
    });
    getProductsForSubCategory(cat.name, cat.id);
  };

  const onProductQuantityChanged = (
    changedQuantity,
    product,
    index,
    isPlus,
    isMinus
  ) => {
    const copyProducts = [...products];
    const singleItem = copyProducts[index];

    var newQuantity = changedQuantity;
    if (isPlus) {
      newQuantity = singleItem.quantity + 1;
    }

    if (isMinus) {
      newQuantity = singleItem.quantity - 1;
    }

    var sub_dollar_amount =
      newQuantity * (singleItem.price - singleItem.discount);
    var total_dollar_amount = sub_dollar_amount;
    if (singleItem.taxable === 1) {
      total_dollar_amount = convertToTwoDecimals(sub_dollar_amount * 1.13);
    }

    const singleItemBeingEdited = {
      ...singleItem,
      quantity: newQuantity,
      discount: singleItem.discount ? singleItem.discount : 0,
      tax_rate: singleItem.tax_rate ? singleItem.tax_rate : 0,
      sub_dollar_amount: sub_dollar_amount,
      total_dollar_amount: total_dollar_amount,
    };
    var payload = {
      index: index,
      item: singleItemBeingEdited,
    };
    const newProducts = updateObjectInArray(copyProducts, payload);

    setProducts(newProducts);
    modifyCart(singleItemBeingEdited);
  };

  const showErrorModal = (title, subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title={title}
        />
      ),
      open: true,
    });
  };

  const createOrderApiCall = async function () {
    const payload = { ...values, products: products };
    let callResult = await makePostApiCall(`createOrder`, payload);
    if (callResult.success) {
      window.location = `/orders/${callResult.data.id}`;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const primaryCategoryBreadcrumbClicked = (currBreadcrumbs) => {
    setSubCategories(null);
    setProducts(null);
    setValues({
      ...values,
      primary_category: null,
      sub_category: null,
    });
    let copy = [...currBreadcrumbs];
    let newCopy = copy.slice(0, 1);
    setBreadCrumbs(newCopy);
  };

  const subCategoryBreadcrumbClicked = (currBreadcrumbs) => {
    setProducts(null);
    setValues({
      ...values,
      primary_category: null,
      sub_category: null,
    });
    let copy = [...currBreadcrumbs];
    let newCopy = copy.slice(0, currBreadcrumbs.length - 1);
    setBreadCrumbs(newCopy);
  };

  const productBreadcrumbClicked = (currBreadcrumbs) => {
    /*
     * Since we are at the product, it's the last breadcrumb
     * and we don't do anything
     */
  };

  const getPrimaryCategoriesForCustomerGroup = async function (
    customerGroupId
  ) {
    let callResult = await makePostApiCall(
      `getPrimaryCategoriesForCustomerGroup`,
      { customer_group_id: customerGroupId }
    );
    if (callResult.success) {
      setPrimaryCategories(callResult.data);
      setBreadCrumbs([
        ...breadCrumbs,
        {
          name: "Categories",
          href: "#",
          current: true,
          onClick: primaryCategoryBreadcrumbClicked,
        },
      ]);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getSubCategoriesOrProductsForPrimaryCategory = async function (
    catName,
    catId,
    customer_group_id
  ) {
    let callResult = await makePostApiCall(
      `getSubCategoriesOrProductsForPrimaryCategory/${catId}`,
      { customer_group_id: customer_group_id }
    );
    if (callResult.success) {
      const type = callResult.data.type;
      if (type === 1) {
        setSubCategories(callResult.data.items);
        setBreadCrumbs([
          ...breadCrumbs,
          {
            name: catName,
            href: "#",
            current: true,
            onClick: subCategoryBreadcrumbClicked,
          },
        ]);
      } else if (type === 2) {
        const mappedItems = callResult.data.items.map((item) => {
          cart.forEach((element) => {
            if (element.id === item.id) {
              item.quantity = element.quantity;
              item.discount = element.discount;
            }
          });

          return item;
        });
        setProducts(mappedItems);
        setBreadCrumbs([
          ...breadCrumbs,
          {
            name: catName,
            href: "#",
            current: true,
            onClick: productBreadcrumbClicked,
          },
        ]);
      } else {
        showErrorModal("It seems no sub category or product is found.");
      }
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getProductsForSubCategory = async function (catName, catId) {
    let callResult = await makePostApiCall(
      `getProductsForSubCategory/${catId}`
    );
    if (callResult.success) {
      const mappedItems = callResult.data.map((item) => {
        if (cart) {
          cart.forEach((element) => {
            if (element.id === item.id) {
              item.quantity = element.quantity;
              item.discount = element.discount;
            }
          });
        }

        return item;
      });
      setProducts(mappedItems);
      setBreadCrumbs([
        ...breadCrumbs,
        {
          name: catName,
          href: "#",
          current: true,
          onClick: productBreadcrumbClicked,
        },
      ]);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openChangeCustomerModal = () => {
    setEmptyModelOpen({
      childComponent: (
        <ChangeCustomerForm
          customers={customers}
          onSubmit={onCustomerSelected}
          setOpen={setEmptyModelOpen}
          orderDetails={orderDetails}
        />
      ),
      open: true,
    });
  };

  return {
    loading,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    showErrorModal,
    createOrderApiCall,
    breadCrumbs,
    primaryCategories,
    subCategories,
    products,
    onCustomerSelected,
    primaryCategorySelected,
    subCategorySelected,
    handleSubmit,
    values,
    onProductQuantityChanged,
    handleInputChange,
    errors,
    openChangeCustomerModal,
    getPrimaryCategoriesForCustomerGroup,
    getCustomersForDropdown,
    customers,
  };
}
