import { useEffect, useState } from "react";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import {
  convertToTwoDecimals,
  orderDefault,
  withRouter,
} from "../../shared/utility";

import { updateObjectInArray } from "../../shared/utility";

import ItemsScreen from "../../components/forms/cart/ItemsScreen";
import OrderSummary from "../../components/forms/cart/OrderSummary";
import { CartViewModel } from "../../viewmodels/cart/CartViewModel";

function Cart(props) {
  const { cart, setCart, orderDetails, setOrderDetails, logout } = props;

  const {
    emptyModal,
    setEmptyModelOpen,
    setShowSimpleNotification,
    showSimpleNotification,
    createOrderFromCart,
  } = CartViewModel(setCart, setOrderDetails, logout);

  const onHandleInputChange = (name, newValue, index) => {
    const copyProducts = [...cart];
    const singleItem = copyProducts[index];

    var sub_dollar_amount = singleItem.total;
    if (name === "price") {
      sub_dollar_amount =
        (newValue - singleItem.discount) * singleItem.quantity;
    } else if (name === "quantity") {
      sub_dollar_amount = newValue * (singleItem.price - singleItem.discount);
    } else if (name === "discount") {
      sub_dollar_amount = (singleItem.price - newValue) * singleItem.quantity;
    }

    var total_dollar_amount = sub_dollar_amount;
    if (singleItem.taxable === 1) {
      total_dollar_amount = convertToTwoDecimals(sub_dollar_amount * 1.13);
    }

    const singleItemBeingEdited = {
      ...singleItem,
      [name]: newValue,
      sub_dollar_amount: sub_dollar_amount,
      total_dollar_amount: total_dollar_amount,
    };
    var payload = {
      index: index,
      item: singleItemBeingEdited,
    };
    var newProducts = updateObjectInArray(copyProducts, payload);
    if (name === "quantity" && newValue === 0) {
      newProducts.splice(index, 1);
    }
    setCart(newProducts);
  };

  const onRemoveProductFromCart = (product, index) => {
    const newProducts = cart.filter((el, elIndex) => elIndex !== index);
    setCart(newProducts);
  };

  function clearCart() {
    setOrderDetails(orderDefault);

    setCart([]);
  }

  return (
    <div className="border-t-2 grid grid-cols-5 divide-x divide-gray-200 h-100 bg-main">
      <div className="px-8 py-4 col-span-3 space-y-4">
        {cart.length > 0 ? (
          <div className="flex">
            <p className="text-xl font-semibold text-gray-900">
              Cart for: {orderDetails.company_name}
            </p>
            <button
              type="submit"
              className="ml-auto justify-center rounded-md bg-gray-400 px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={clearCart}
            >
              Clear cart
            </button>
          </div>
        ) : null}

        <ItemsScreen
          cart={cart}
          onRemoveProductFromCart={onRemoveProductFromCart}
          onHandleInputChange={onHandleInputChange}
        />
      </div>

      <OrderSummary
        orderDetails={orderDetails}
        cart={cart}
        setOrderDetails={setOrderDetails}
        errors={{}}
        createOrderFromCart={createOrderFromCart}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    orderDetails: state.cart.orderDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCart: (newCart) => dispatch(actions.setCart(newCart)),
    setOrderDetails: (customer) => dispatch(actions.setOrderDetails(customer)),
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));
