import React, { useEffect, useRef, useState } from "react";

import { useForm } from "../useForm";

import SelectMenu from "../../controls/SelectMenu";
import {
  convertToDefEventPara,
  makeBeepNoise,
  shippingStatusOptions,
  updateObjectInArray,
} from "../../../shared/utility";
import OrderItemsForShipping from "./OrderItemsForShipping";
import DeleteConfirmationForm from "../DeleteConfirmationForm";
import { classNames } from "primereact/utils";
import InputTextOnly from "../../controls/InputTextOnly";
import AddProductToShippingForm from "./modals/AddProductToShippingForm";
import ButtonSpinner from "../../controls/ButtonSpinner";

const initialFValues = {
  id: 0,
  customer_id: 0,
  subtotal_dollar_amount: 0,
  total_dollar_amount: 0,
  order_id: 0,
  orderItems: [],
};

export default function CreateNewShippingForm(props) {
  const {
    orders,
    onFetchOrderItems,
    isEdit,
    loading,
    shipping,
    setEmptyModelOpen,
    showErrorModal,
    onFetchItemByBarcode,
    updateShippingApiCall,
    createShippingApiCall,
    addItemToShipping,
    deleteShippingItemApiCall,
    employeeOptions,
    deleteShipping,
    printShipping,
    createInvoice,
    invoiceInProgress,
    updateShippingAmountApiCall,
    downloadInvoice,
  } = props;

  const [barcodeScanInProgress, setBarcodeScanInProgress] = useState(false);

  const barcodeInputRef = useRef(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    temp.order_id = null;
    if (values.order_id === 0) {
      temp.order_id = "Please fill out field.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    false,
    validate
  );

  useEffect(() => {
    if (shipping) {
      const shippingCopy = {
        ...shipping,
        status: shippingStatusOptions.filter(
          (option) => option.value == shipping.status
        )[0],
      };
      setValues(shippingCopy);
    }
  }, [shipping]);

  function findIndexForOrderItem(product_id) {
    return values.orderItems.findIndex(
      (element) => element.product_id === product_id
    );
  }

  const updateShipping = () => {
    if (validate()) {
      updateShippingApiCall(values);
    }
  };

  const createShipping = () => {
    if (isEdit) {
      updateShipping();
    } else {
      if (validate()) {
        createShippingApiCall(values);
      }
    }
  };

  const updateShippingAmountForItem = (rowData, newAmount) => {
    const itemIndex = findIndexForOrderItem(rowData.product_id);

    if (itemIndex >= 0) {
      const orderItems = [...values.orderItems];
      const orderItem = orderItems[itemIndex];

      var newAmountCopy = newAmount;
      if (!(parseFloat(newAmount) >= 0)) {
        newAmountCopy = 0;
      }

      const orderItemBeingEdited = {
        ...orderItem,
        shipped_amount: newAmountCopy,
      };
      var payload = {
        index: itemIndex,
        item: orderItemBeingEdited,
      };
      const newOrderItems = updateObjectInArray(orderItems, payload);
      setValues({
        ...values,
        orderItems: newOrderItems,
        scanned_box_label: "",
      });
    }
  };

  const continueAddingItemToShipping = async function (
    shippingItem,
    orderItems,
    orderItem,
    itemIndex
  ) {
    let callResult = { success: false };
    var shippingItemCopy = shippingItem;
    if (isEdit) {
      callResult = await addItemToShipping(
        values.id,
        orderItem.id,
        shippingItem
      );
      shippingItemCopy = callResult.data;
    }

    if (callResult.success || !isEdit) {
      let newShippingItems = [shippingItem];
      if (orderItem.shippingItems) {
        newShippingItems = [...orderItem.shippingItems, shippingItemCopy];
      }

      const orderItemBeingEdited = {
        ...orderItem,
        shipped_amount:
          parseFloat(orderItem.shipped_amount) +
          parseFloat(shippingItem.shipped_amount),
        shippingItems: newShippingItems,
      };
      var payload = {
        index: itemIndex,
        item: orderItemBeingEdited,
      };
      const newOrderItems = updateObjectInArray(orderItems, payload);
      setValues({
        ...values,
        orderItems: newOrderItems,
        scanned_box_label: "",
      });
    }
    setEmptyModelOpen({
      open: false,
      childComponent: null,
    });
  };

  const normalItemScanned = async function (shippingItem, itemIndex) {
    const orderItems = [...values.orderItems];
    const orderItem = orderItems[itemIndex];
    const itemAlreadyScanned = orderItem.shippingItems.findIndex((element) => {
      return element.shipping_barcode === shippingItem.shipping_barcode;
    });
    if (itemAlreadyScanned >= 0) {
      showErrorModal("Item already scanned");
      return;
    }

    if (
      parseInt(orderItem.quantity) < parseFloat(shippingItem.shipped_amount) &&
      shippingItem.selling_unit_of_measure !== 1
    ) {
      setEmptyModelOpen({
        childComponent: (
          <DeleteConfirmationForm
            buttonMessage="OK"
            confirmAction={() =>
              continueAddingItemToShipping(
                shippingItem,
                orderItems,
                orderItem,
                itemIndex
              )
            }
            message={`Customer ordered ${orderItem.quantity}, you are trying to ship ${shippingItem.shipped_amount}.`}
            setOpen={setEmptyModelOpen}
          />
        ),
        open: true,
      });
    } else {
      continueAddingItemToShipping(
        shippingItem,
        orderItems,
        orderItem,
        itemIndex
      );
    }
  };

  const handleBarcodeScan = async function (scannedBarcode) {
    setValues({
      ...values,
      scanned_box_label: "",
    });
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus();
    }
    let callResult = await onFetchItemByBarcode(scannedBarcode);
    if (callResult.success) {
      makeBeepNoise();
      const inventoryItem = callResult.data;
      var prodIdToSearch = inventoryItem.inventory_item.product_id;
      prodIdToSearch = inventoryItem.inventory_item.product_id;

      const itemIndex = findIndexForOrderItem(prodIdToSearch);

      setEmptyModelOpen({
        childComponent: (
          <AddProductToShippingForm
            product={inventoryItem.inventory_item}
            confirmAction={(shippingItem) =>
              normalItemScanned(shippingItem, itemIndex)
            }
            isEdit={false}
            setOpen={() => {
              setEmptyModelOpen({
                open: false,
                childComponent: null,
              });
            }}
          />
        ),
        open: true,
      });
    } else {
      showErrorModal("We could not find that item.");
    }
  };

  const makeDeleteShippingItemApiCall = async function (product) {
    const id = product.id;
    const barcode = product.shipping_barcode;
    const product_id = product.product_id;

    var onItemDeleted = { success: false };

    const itemIndex = findIndexForOrderItem(product_id);

    if (isEdit) {
      onItemDeleted = await deleteShippingItemApiCall(id);
    }

    if (onItemDeleted.success || !isEdit) {
      if (itemIndex >= 0) {
        const orderItems = [...values.orderItems];
        const orderItem = orderItems[itemIndex];

        const filteredItems = orderItem.shippingItems.filter((element) => {
          return element.shipping_barcode !== barcode;
        });
        const orderItemBeingEdited = {
          ...orderItem,
          shipped_amount:
            Math.round((orderItem.shipped_amount - product.amount) * 100) / 100,
          shippingItems: filteredItems,
        };
        var payload = {
          index: itemIndex,
          item: orderItemBeingEdited,
        };
        const newOrderItems = updateObjectInArray(orderItems, payload);
        setValues({ ...values, orderItems: newOrderItems });
        setEmptyModelOpen({
          childComponent: null,
          open: false,
        });
      }
    }
  };

  const askDeleteShippingItem = function (product) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => makeDeleteShippingItemApiCall(product)}
          message={`Are you sure you want to delete ${product.shipping_barcode}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  return (
    <div className="grid grid-col-1 sm:grid-cols-3 gap-3 h-full">
      <div className="sm:col-span-2 card-custom p-3 order-2 sm:order-1">
        <div>
          <div className="flex space-x-2 mb-1">
            <label
              htmlFor="scanned_box_label"
              className={"text-sm block font-medium text-gray-900"}
            >
              Scan barcode
            </label>
          </div>
          <input
            placeholder="Scan box label"
            name="scanned_box_label"
            disabled={barcodeScanInProgress}
            ref={barcodeInputRef}
            onChange={handleInputChange}
            value={values.scanned_box_label}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleBarcodeScan(e.target.value);
            }}
            type="search"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          />
        </div>
        <OrderItemsForShipping
          isEdit={isEdit}
          askDeleteShippingItem={askDeleteShippingItem}
          updateShippingAmountForItem={updateShippingAmountForItem}
          updateShippingAmountApiCall={updateShippingAmountApiCall}
          loading={false}
          orderItems={values.orderItems}
          setEmptyModelOpen={setEmptyModelOpen}
        />
      </div>
      <div className="space-y-5 order-1 sm:order-2">
        <div className="card-custom p-3 space-y-2">
          <SelectMenu
            handleInputChange={(e) => {
              handleInputChange(
                convertToDefEventPara("order_id", e.target.value)
              );
              onFetchOrderItems(e.target.value.value);
            }}
            name="order_id"
            options={orders}
            defaultValue={isEdit ? shipping.order : null}
            title={"Order to ship"}
            disabled={isEdit}
            error
          />

          <SelectMenu
            handleInputChange={handleInputChange}
            name="created_by"
            options={employeeOptions}
            defaultValue={isEdit ? shipping.created_by : null}
            title={"Employee"}
            error={errors.created_by}
            showLabel={true}
          />

          <InputTextOnly
            label="Invoice #"
            name="invoice_number"
            onChange={handleInputChange}
            value={values.invoice_number}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            error={errors.invoice_number}
          />

          <InputTextOnly
            label="Seal #"
            name="seal_number"
            onChange={handleInputChange}
            value={values.seal_number}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            error={errors.seal_number}
          />

          <SelectMenu
            handleInputChange={handleInputChange}
            name="status"
            options={shippingStatusOptions.filter(
              (option) => option.value !== -1
            )}
            value={values.status}
            title={"Status"}
            error={errors.status}
            showLabel={true}
          />
          <div className="flex flex-shrink-0 py-4">
            <button
              type="button"
              className={classNames(
                values.id > 0 ? null : "hidden",
                "inline-flex justify-center rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 mr-4"
              )}
              onClick={() => printShipping()}
            >
              Print
            </button>
            <button
              type="submit"
              className="inline-flex justify-center rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={createShipping}
            >
              {values.id > 0 ? "Save" : "Submit"}
            </button>
            <button
              type="submit"
              className={classNames(
                values.id > 0 ? null : "hidden",
                "ml-4 inline-flex justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-700"
              )}
              onClick={() => createInvoice(shipping.order_id)}
            >
              {invoiceInProgress ? <ButtonSpinner spin={true} /> : null}
              Invoice
            </button>
            <button
              type="submit"
              className={classNames(
                values.id > 0 ? null : "hidden",
                "ml-4 inline-flex justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              )}
              onClick={downloadInvoice}
            >
              Download
            </button>
            <button
              type="submit"
              className={classNames(
                values.id > 0 ? null : "hidden",
                "ml-4 inline-flex justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              )}
              onClick={deleteShipping}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
