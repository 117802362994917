import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, ShoppingCartIcon } from "@heroicons/react/20/solid";

import { classNames } from "../../shared/utility";

export default function HeaderLayout(props) {
  const { cart, openCart, sidebarOpen, logout, setSidebarOpen } = props;

  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    var currCount = 0;
    if (cart) {
      cart.forEach((element) => {
        currCount += parseFloat(element.quantity);
      });
    }
    setCartCount(currCount);
  }, [cart]);

  const userNavigation = [
    {
      name: "My account",
      action: () => {
        window.location = "/my-account";
      },
    },
    { name: "Sign out", action: () => logout() },
  ];

  return (
    <div className="hide-for-print top-0 flex h-16 shrink-0 items-center gap-x-4 px-4 sm:gap-x-6 sm:px-6 lg:px-8">
      <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-700"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <span className="sr-only">Open main sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <form className="relative flex flex-1" action="#" method="GET"></form>
        <div className="flex items-center gap-x-2 lg:gap-x-2">
          <button
            class="-m-2.5 p-2.5 py-4 px-1 relative border-2 border-transparent text-gray-800 rounded-full hover:text-gray-400 focus:outline-none focus:text-gray-500 transition duration-150 ease-in-out"
            aria-label="Cart"
            onClick={openCart}
          >
            <ShoppingCartIcon className="h-6 w-6" aria-hidden="true" />
            <span class="absolute inset-0 object-right-top -mr-6">
              <div class="inline-flex items-center px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-red-500 text-white">
                {cartCount}
              </div>
            </span>
          </button>

          {/* Separator */}
          <div aria-hidden="true" />

          {/* Profile dropdown */}
          <Menu as="div" className="relative">
            <Menu.Button className="-m-1.5 flex items-center p-1.5">
              <span className="sr-only">Open my account</span>
              <span className="flex lg:items-center">
                <span
                  className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                  aria-hidden="true"
                >
                  My account
                </span>
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <button
                        className={classNames(
                          active ? "bg-gray-50" : "",
                          "block px-3 py-1 text-sm leading-6 text-gray-900"
                        )}
                        onClick={item.action}
                      >
                        {item.name}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
}
