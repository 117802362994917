import { Link } from "react-router-dom";
import Pills, { PillType } from "../../components/controls/Pills";
import {
  convertStatus,
  formatDate,
  formatDateWithTime,
} from "../../shared/utility";

export default function OrdersCardsForMobile(props) {
  const { askDeleteOrder, orders } = props;

  return (
    <div className="block lg:hidden p-4 space-y-3">
      {orders.data.length > 0 ? (
        orders.data.map((order) => (
          <div
            className="card-custom p-4 space-y-1"
            onClick={() => (window.location = `/orders/${order.id}`)}
          >
            <div>
              <h3>Order - {order.po_number}</h3>
              <p>Customer: {order.company_name}</p>
              <p>Order date: {formatDateWithTime(order.created_at)}</p>
              {order.expected_date ? (
                <p>Delivery date: {formatDate(order.expected_date)}</p>
              ) : null}
            </div>
            <Pills content={convertStatus(order.status)} />
            <div className="bg-blue-300 rounded p-1">
              <p>Total: ${order.total_dollar_amount}</p>
            </div>
          </div>
        ))
      ) : (
        <tr>
          <td
            className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center"
            colSpan={4}
          >
            No Orders
          </td>
        </tr>
      )}
    </div>
  );
}
