import { useEffect, useState } from "react";
import { CreateOrderViewModel } from "../../viewmodels/orders/CreateOrderViewModel";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import EmptyModal from "../../components/modals/EmptyModal";
import SlideOver from "../../components/slideOver/SlideOver";
import SimpleNotification from "../../components/SimpleNotification";
import CreateNewOrderForm from "../../components/forms/orders/CreateNewOrderForm";
import BreadCrumbs from "../../components/controls/BreadCrumbs";

function CreateOrder(props) {
  const { cart, orderDetails, modifyCart, logout, setOrderDetails } = props;
  const {
    loading,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    showErrorModal,
    breadCrumbs,
    primaryCategories,
    subCategories,
    products,
    primaryCategorySelected,
    subCategorySelected,
    onProductQuantityChanged,
    openChangeCustomerModal,
    getPrimaryCategoriesForCustomerGroup,
    getCustomersForDropdown,
  } = CreateOrderViewModel(
    cart,
    orderDetails,
    modifyCart,
    logout,
    setOrderDetails
  );

  useState(() => {
    if (orderDetails) {
      getPrimaryCategoriesForCustomerGroup(orderDetails.customer_group_id);
    }
    getCustomersForDropdown();
  }, []);

  var customer_id = 0;
  if (orderDetails) {
    customer_id = orderDetails.customer_id;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-4">
      <SimpleNotification
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />

      <div className="mt-2 space-y-4">
        <div className="sm:flex justify-between">
          <h1 className="text-xl font-semibold leading-6 text-gray-900 my-auto">
            {orderDetails ? (
              orderDetails.company_name ? (
                <p>
                  <span className="text-gray-500">Creating order for</span>{" "}
                  {orderDetails.company_name}
                </p>
              ) : null
            ) : (
              "Create Order"
            )}
          </h1>
          <button
            type="button"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center mt-3"
            onClick={openChangeCustomerModal}
          >
            {customer_id > 0 ? "Change Customer" : "Choose Customer"}
          </button>
        </div>
        <BreadCrumbs pgs={breadCrumbs} />
        <div className="sm:items-center">
          {customer_id > 0 ? (
            <CreateNewOrderForm
              primaryCategories={primaryCategories}
              subCategories={subCategories}
              products={products}
              primaryCategorySelected={primaryCategorySelected}
              subCategorySelected={subCategorySelected}
              onProductQuantityChanged={onProductQuantityChanged}
            />
          ) : (
            <div className="">
              <p className="font-bold text-xl">
                Choose a customer to start order
              </p>
              <button
                type="button"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center mt-3"
                onClick={openChangeCustomerModal}
              >
                Choose Customer
              </button>
            </div>
          )}
        </div>
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        customClass="max-w-3xl"
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    orderDetails: state.cart.orderDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    modifyCart: (item) => dispatch(actions.modifyCart(item)),
    setOrderDetails: (customer) => dispatch(actions.setOrderDetails(customer)),
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateOrder)
);
