import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import SideNavigationBar from "../../components/sideNavigationBar/SideNavigationBar";

import Customers from "../../views/customers/Customers";

import Users from "../../views/setup/users/Users";
import UserDetails from "../../views/setup/users/user/UserDetails";

import { useEffect, useRef, useState } from "react";

import { classNames, withRouter } from "../../shared/utility";
import MyAccount from "../../views/myAccount/MyAccount";
import HeaderLayout from "./HeaderLayout";
import Dashboard from "../../views/dashboard/Dashboard";
import CustomerDetails from "../../views/customers/customer/CustomerDetails";
import CustomerGroups from "../../views/customerGroups/CustomerGroups";
import CustomerGroupDetails from "../../views/customerGroups/group/CustomerGroupDetails";
import ProductCategories from "../../views/primaryCategories/ProductCategories";
import ProductCategoryDetails from "../../views/primaryCategories/category/ProductCategoryDetails";
import Products from "../../views/products/Products";
import CreateOrder from "../../views/orders/CreateOrder";
import ManageOrders from "../../views/orders/ManageOrders";
import OrderDetails from "../../views/orders/OrderDetails";
import LoblawsSkus from "../../views/orders/LoblawsSkus";
import ManageShippings from "../../views/shipping/ManageShippings";
import CreateShipping from "../../views/shipping/CreateShipping";
import ShippingDetails from "../../views/shipping/ShippingDetails";
import ProductDetails from "../../views/products/product/ProductDetails";
import SubCategories from "../../views/subCategories/SubCategories";
import SubCategoryDetails from "../../views/subCategories/category/SubCategoryDetails";
import SlideOver from "../slideOver/SlideOver";
import Cart from "../../views/cart/Cart";

function DefaultLayout(props) {
  const {
    cart,
    orderDetails,
    setCart,
    setOrderDetails,
    logout,
    userType,
    email,
  } = props;
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const size = useWindowSize();

  useEffect(() => {
    if (size.width < 991) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
  }, [size]);

  const outsideClickRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        outsideClickRef.current &&
        !outsideClickRef.current.contains(event.target) &&
        size.width < 991
      ) {
        setSidebarOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [outsideClickRef, size]);

  return (
    <div
      className={classNames(
        sidebarOpen
          ? size.width < 991
            ? "scroll-blocked layout-mobile-active"
            : ""
          : "layout-static-inactive",
        "layout-container layout-static layout-light"
      )}
    >
      <SideNavigationBar
        userType={userType}
        outsideClickRef={outsideClickRef}
      />

      <div className="layout-content-wrapper">
        <div className="layout-mask"></div>
        <HeaderLayout
          cart={cart}
          openCart={() => (window.location = "/cart")}
          sidebarOpen={sidebarOpen}
          logout={logout}
          setSidebarOpen={setSidebarOpen}
        />
        <Routes>
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/new" element={<CustomerDetails />} />
          <Route path="/customers/:id" element={<CustomerDetails />} />

          <Route path="/customer-groups" element={<CustomerGroups />} />
          <Route
            path="/customer-groups/new"
            element={<CustomerGroupDetails />}
          />
          <Route
            path="/customer-groups/:groupId"
            element={<CustomerGroupDetails />}
          />

          <Route path="/users" element={<Users />} />
          <Route path="/users/new" element={<UserDetails />} />
          <Route path="/users/:userId" element={<UserDetails />} />

          <Route path="/products" element={<Products />} />
          <Route path="/products/:productId" element={<ProductDetails />} />

          <Route path="/primary-categories" element={<ProductCategories />} />
          <Route
            path="/primary-categories/new"
            element={<ProductCategoryDetails isEdit={false} />}
          />
          <Route
            path="/primary-categories/:categoryId"
            element={<ProductCategoryDetails isEdit={true} />}
          />
          <Route path="/sub-categories" element={<SubCategories />} />
          <Route
            path="/sub-categories/new"
            element={<SubCategoryDetails isEdit={false} />}
          />
          <Route
            path="/sub-categories/:categoryId"
            element={<SubCategoryDetails isEdit={true} />}
          />

          <Route path="/create-order" element={<CreateOrder />} />
          <Route path="/orders" element={<ManageOrders />} />
          <Route path="/orders/:orderId" element={<OrderDetails />} />

          <Route path="/loblaws-skus" element={<LoblawsSkus />} />

          <Route path="/shippings" element={<ManageShippings />} />
          <Route path="/create-shipping" element={<CreateShipping />} />
          <Route path="/shippings/:shippingId" element={<ShippingDetails />} />

          <Route path="/cart" element={<Cart />} />

          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    orderDetails: state.cart.orderDetails,
    userType: state.auth.userType,
    email: state.auth.email,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCart: (newCart) => dispatch(actions.setCart(newCart)),
    setOrderDetails: (customer) => dispatch(actions.setOrderDetails(customer)),
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DefaultLayout)
);

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
