import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";

import { SimpleNotificationType } from "../../components/SimpleNotification";
import { orderDefault } from "../../shared/utility";

export function CartViewModel(setCart, setOrderDetails, logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });

  const createOrderFromCart = async function (payload) {
    let callResult = await makePostApiCall(`createOrder`, {
      customer_id: payload.orderDetails.customer_id,
      customer_group_id: payload.orderDetails.customer_group_id,
      po_number: payload.orderDetails.po_number,
      expected_date: payload.orderDetails.expected_date,
      notes: payload.notes,
      products: payload.cart,
    });
    if (callResult.success) {
      window.location = `/orders/${callResult.data.id}`;

      setCart([]);
      setOrderDetails(orderDefault);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    emptyModal,
    setEmptyModelOpen,
    setShowSimpleNotification,
    showSimpleNotification,
    createOrderFromCart,
  };
}
