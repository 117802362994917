import InputTextOnly from "../../controls/InputTextOnly";

export default function CustomerAddressesForm(props) {
  const { address_billing, address_shipping } = props;

  return (
    <div className="p-4">
      <div className="space-y-3">
        <div>
          <p className="text-lg font-semibold">Billing Address</p>
          <div className="mt-3 grid grid-cols-1 gap-4 max-w-4xl">
            <div>
              <InputTextOnly
                label="Billing Address Line 1"
                name="line1"
                value={address_billing.line1}
                labelOn={true}
                disabled={true}
              />
            </div>
            <div>
              <InputTextOnly
                label="Billing Address Line 2"
                name="line2"
                value={address_billing.line2}
                labelOn={true}
                disabled={true}
              />
            </div>
          </div>
          <div className="mt-3 grid grid-cols-4 gap-4 max-w-4xl">
            <div>
              <InputTextOnly
                label="City"
                name="city"
                value={address_billing.city}
                labelOn={true}
                disabled={true}
              />
            </div>
            <div>
              <InputTextOnly
                label="Province"
                name="province"
                value={address_billing.province}
                labelOn={true}
                disabled={true}
              />
            </div>
            <div>
              <InputTextOnly
                label="State"
                name="state"
                value={address_billing.state}
                labelOn={true}
                disabled={true}
              />
            </div>
            <div>
              <InputTextOnly
                label="Country"
                name="country_code"
                value={address_billing.country_code}
                labelOn={true}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="pt-10">
          <p className="text-lg font-semibold">Shipping Address</p>
          <div className="mt-3 grid grid-cols-1 gap-4 max-w-4xl">
            <div>
              <InputTextOnly
                label="Billing Address Line 1"
                name="line1"
                value={address_shipping.line1}
                labelOn={true}
                disabled={true}
              />
            </div>
            <div>
              <InputTextOnly
                label="Billing Address Line 2"
                name="line2"
                value={address_shipping.line2}
                labelOn={true}
                disabled={true}
              />
            </div>
          </div>
          <div className="mt-3 grid grid-cols-4 gap-4 max-w-4xl">
            <div>
              <InputTextOnly
                label="City"
                name="city"
                value={address_shipping.city}
                labelOn={true}
                disabled={true}
              />
            </div>
            <div>
              <InputTextOnly
                label="Province"
                name="province"
                value={address_shipping.province}
                labelOn={true}
                disabled={true}
              />
            </div>
            <div>
              <InputTextOnly
                label="State"
                name="state"
                value={address_shipping.state}
                labelOn={true}
                disabled={true}
              />
            </div>
            <div>
              <InputTextOnly
                label="Country"
                name="country_code"
                value={address_shipping.country_code}
                labelOn={true}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
