import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  cart: [],
  orderDetails: null,
};

const modifyCart = (state, action) => {
  return updateObject(state, { cart: action.cart });
};

const modifyOrderDetails = (state, action) => {
  return updateObject(state, { orderDetails: action.orderDetails });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CART:
      return modifyCart(state, action);
    case actionTypes.SET_ORDER_DETAILS:
      return modifyOrderDetails(state, action);

    default:
      return state;
  }
};

export default reducer;
