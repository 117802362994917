import { useEffect, useState } from "react";
import { useForm } from "../useForm";

import InputTextOnly from "../../controls/InputTextOnly";
import SelectMenu from "../../controls/SelectMenu";
import ImportMediaDropzone from "../../importMedia/ImportMediaDropzone";

const initialFValues = {
  name: "",
};

export default function SubCategoryDetailsForm(props) {
  const { category, actionCall, isEdit, primaryCategories } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    temp.name = null;
    if (values.name === "") {
      temp.name = "Please fill out field.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      actionCall(values);
    }
  };

  useEffect(() => {
    if (category) {
      setValues({ ...values, ...category });
    }
  }, [category, setValues]);

  const [image1, setImage1] = useState(null);

  return (
    <form onSubmit={handleSubmit}>
      <div className="p-4">
        <div className="space-y-3">
          <SelectMenu
            handleInputChange={handleInputChange}
            name="parent_category"
            defaultValue={category.parent_category}
            options={primaryCategories}
            title={"Parent category"}
          />
          <InputTextOnly
            label="Sub Category name"
            name="name"
            onChange={handleInputChange}
            value={values.name}
            labelOn={true}
            error={errors.name}
          />
          <div>
            <div className="flex space-x-2">
              <label
                htmlFor="image1"
                className="block text-sm font-medium text-gray-900"
              >
                Group image
              </label>
            </div>
            {image1 ? (
              <div>
                <img src={image1} height="140" width="140" alt="New logo" />
              </div>
            ) : isEdit && values.image1 ? (
              <img
                src={values.image1}
                alt={values.image1}
                title={values.image1}
                className="media-image rounded-md"
                height="140"
                width="140"
              />
            ) : null}
            <ImportMediaDropzone
              fileName="image1"
              handleInputChange={handleInputChange}
              setUploadedImage={setImage1}
            />
          </div>

          <div className="sm:flex-no-wrap">
            <div className="my-auto flex items-center">
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center mt-3"
              >
                {isEdit ? "Save" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
