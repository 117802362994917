import React, { useEffect } from "react";

import { useForm } from "../../useForm";

import SelectMenu from "../../../controls/SelectMenu";
import DatePickerInput from "../../../controls/DatePickerInput";
import InputTextOnly from "../../../controls/InputTextOnly";

const initialFValues = {
  po_number: null,
  expected_date: null,
  customer: null,
};

export default function ChangeCustomerForm(props) {
  const { orderDetails, customers, onSubmit, setOpen } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    false,
    validate
  );

  useEffect(() => {
    var customer = { value: -1, label: "" };
    if (orderDetails) {
      customer = {
        value: orderDetails.customer_id,
        label: orderDetails.company_name,
      };
    }
    setValues({
      ...initialFValues,
      ...orderDetails,
      customer: customer,
    });
  }, []);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-1 gap-3">
      <p className="text-lg font-bold text-center">Select/Change customer</p>
      <SelectMenu
        handleInputChange={handleInputChange}
        name="customer"
        options={customers}
        title="Customer"
        value={values.customer}
        error={errors.customer}
      />

      <InputTextOnly
        label="PO #"
        name="po_number"
        onChange={handleInputChange}
        value={values.po_number}
        labelOn={true}
        type="text"
        error={errors.po_number}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
      />

      <DatePickerInput
        label="Expected Date"
        name="expected_date"
        onChange={handleInputChange}
        value={values.expected_date}
        labelOn={true}
        minDate={new Date()}
        dateFormat="LLL dd, YYY"
        type="text"
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm h-10"
        error={errors.expected_date}
      />
      <div className="sm:flex-no-wrap my-auto flex items-center">
        <button
          onClick={() => {
            onSubmit(values);
            setOpen({
              open: false,
            });
          }}
          className="inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center"
        >
          OK
        </button>
        <button
          onClick={() =>
            setOpen({
              open: false,
            })
          }
          className="ml-2 inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 items-center"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
