import {
  convertToTwoDecimals,
  updateObjectInArray,
} from "../../shared/utility";
import * as actionTypes from "./actionTypes";

function findIndexForCartItem(cart, id) {
  return cart.findIndex((element) => element.id === id);
}

export const dispatchCart = (cart) => {
  localStorage.setItem(`cart`, JSON.stringify(cart));
  return {
    type: actionTypes.SET_CART,
    cart: cart,
  };
};

export const dispatchOrderDetails = (orderDetails) => {
  localStorage.setItem(`orderDetails`, JSON.stringify(orderDetails));
  return {
    type: actionTypes.SET_ORDER_DETAILS,
    orderDetails: orderDetails,
  };
};

export const setCart = (cart) => {
  return (dispatch) => {
    dispatch(dispatchCart(cart));
  };
};

export const modifyCart = (item) => {
  const storedCartItems = JSON.parse(localStorage.getItem(`cart`));

  var cartCopy = [];
  if (storedCartItems) {
    cartCopy = [...storedCartItems];
    const itemIndex = findIndexForCartItem(cartCopy, item.id);
    if (itemIndex > -1) {
      var payload = {
        index: itemIndex,
        item: item,
      };
      cartCopy = updateObjectInArray(cartCopy, payload);

      if (item.quantity === 0) {
        cartCopy.splice(itemIndex, 1);
      }
    } else {
      cartCopy = [...cartCopy, item];
    }
  } else {
    cartCopy = [item];
  }

  return (dispatch) => {
    dispatch(dispatchCart(cartCopy));
  };
};

export const autchCheckCart = () => {
  return (dispatch) => {
    const storedCartItems = JSON.parse(localStorage.getItem(`cart`));
    dispatch(dispatchCart(storedCartItems));
  };
};

export const authCheckOrderDetails = () => {
  return (dispatch) => {
    const orderDetails = JSON.parse(localStorage.getItem(`orderDetails`));
    dispatch(dispatchOrderDetails(orderDetails));
  };
};

export const setOrderDetails = (orderDetails) => {
  return (dispatch) => {
    dispatch(dispatchOrderDetails(orderDetails));
  };
};
